import React, { useState } from 'react'
import styled from 'styled-components'
import Moment from 'moment'
import 'moment/locale/ru'
import { extendMoment } from 'moment-range'
import { Form as AntForm, Button, message, Select } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { useCompany } from '../hooks/company'

import { CREATE_ONE_SCHEDULE, FIND_MANY_MASTER, FIND_MANY_SERVICE } from '../gqls'

const moment = extendMoment(Moment)

const Form = styled(AntForm)`
    max-width: 600px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const week = [
    {
        value: 'Понедельник',
        label: 'Понедельник'
    },
    {
        value: 'Вторник',
        label: 'Вторник'
    },
    {
        value: 'Среда',
        label: 'Среда'
    },
    {
        value: 'Четверг',
        label: 'Четверг'
    },
    {
        value: 'Пятница',
        label: 'Пятница'
    },
    {
        value: 'Суббота',
        label: 'Суббота'
    },
    {
        value: 'Воскресенье',
        label: 'Воскресенье'
    }
]

const AddSchedule = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()

    const { company } = useCompany()

    const [masters, setMasters] = useState([])
    const [services, setServices] = useState([])

    useQuery(FIND_MANY_MASTER, {
        variables: {
            where: {
                companyId: {
                    equals: company?.id
                }
            }
        },
        onCompleted: ({ findManyMaster }) => {
            const mastersData = findManyMaster.map(object => ({
                label: object.name,
                value: object.id,
            }))
            setMasters(mastersData)
        },
        fetchPolicy: 'network-only'
    })

    useQuery(FIND_MANY_SERVICE, {
        variables: {
            where: {
                companyId: {
                    equals: company?.id
                }
            }
        },
        onCompleted: ({ findManyService }) => {
            const servicesData = findManyService.map(object => ({
                label: (object?.name || object?.category?.name) || 'Отсутвует',
                value: object.id,
            }))
            setServices(servicesData)
        },
        fetchPolicy: 'network-only'
    })

    const [createOneScheduleMutation, { loading: createOneScheduleLoading }] = useMutation(CREATE_ONE_SCHEDULE, {
        onCompleted: () => {
            form.resetFields()
            message.success('График добавлен')
            navigate(-1)
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const getTimesRange = () => {
        const dayStart = moment().startOf('day').hours(0).minutes(0)
        const dayEnd = moment().startOf('day').hours(23).minutes(59)
        const range = moment.range(dayStart, dayEnd)
        const timeArray = Array.from(range.by('minutes', { step: 15 }))
        return timeArray.map(object => ({
            value: object.format('HH:mm'),
            label: object.format('HH:mm') + ' - ' + object.add(15, 'minutes').format('HH:mm'),
        }))
    }

    const handleSubmit = async (values) => {
        await createOneScheduleMutation({
            variables: {
                data: {
                    times: values.times || [],
                    week: values.week,
                    masters: values.masters || values.masters?.length > 0 ? {
                        connect: values.masters.map(object => ({
                            id: object
                        }))
                    } : undefined,
                    services: {
                        connect: values.services.map(object => ({
                            id: object
                        }))
                    },
                    company: {
                        connect: {
                            id: company.id
                        }
                    }
                }
            }
        })
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item name='services' label='Выберите услугу' rules={[rules.required]}>
                    <Select
                        placeholder='Выберите услугу'
                        options={services}
                        onChange={(value) => {
                            console.log(value)
                        }}
                        mode='multiple'
                        optionFilterProp='label'
                    />
                </Form.Item>
                <Form.Item name='week' label='Выберите день недели' rules={[rules.required]}>
                    <Select
                        placeholder='Выберите день недели'
                        options={week}
                        mode='multiple'
                        optionFilterProp='label'
                    />
                </Form.Item>
                <Form.Item name='times' label='Выберите интервал'>
                    <Select
                        mode='multiple'
                        placeholder='Выберите интервал'
                        options={getTimesRange()}
                    />
                </Form.Item>
                <Form.Item name='masters' label='Выберите мастера'>
                    <Select
                        placeholder='Выберите мастера'
                        options={masters}
                        mode='multiple'
                        optionFilterProp='label'
                    />
                </Form.Item>
                <Button loading={createOneScheduleLoading} type='primary' htmlType='submit'>
                    Добавить
                </Button>
            </Form>
        </>
    )
}

export default AddSchedule
