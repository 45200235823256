import React from 'react'
import styled from 'styled-components'
import { Form as AntForm, Button, message, Typography, Select, InputNumber, TimePicker } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import axios from 'axios'

import { FIND_UNIQUE_REQUEST, UPDATE_ONE_REQUEST } from '../gqls'

import { useCompany } from '../hooks/company'

const { Text } = Typography

const Form = styled(AntForm)`
    max-width: 600px;
`

const format = 'HH:mm'

const statuses = {
    '1': 'принята',
    '4': 'отменена'
}

const Request = () => {
    const [form] = Form.useForm()
    const { id } = useParams()

    const { company } = useCompany()

    const { data, loading, error } = useQuery(FIND_UNIQUE_REQUEST, {
        variables: {
            where: {
                id
            }
        },
        onCompleted: ({ findUniqueRequest }) => {
            form.setFieldsValue({
                status: findUniqueRequest.status,
                master: findUniqueRequest?.master?.id,
                price: findUniqueRequest.price
            })
        },
        fetchPolicy: 'network-only'
    })

    const [updateOneRequestMutation, { loading: updateOneRequestLoading }] = useMutation(UPDATE_ONE_REQUEST, {
        onCompleted: () => {
            message.success('Данные обновлены')
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    if (loading || error) return null

    const { findUniqueRequest } = data

    const handleSubmit = async (values) => {
        await updateOneRequestMutation({
            variables: {
                where: {
                    id
                },
                data: {
                    status: {
                        set: values.status
                    },
                }
            }
        })
        if (findUniqueRequest.user.pushId && (values.status === '1' || values.status === '4')) {
            try {
                await axios.post('https://admin.okoshkiclick.ru/push', {
                    to: findUniqueRequest.user.pushId,
                    title: 'Статус заявки',
                    body: `Ваша заявка была ${statuses[values.status]}`
                })
            } catch (error) {
                console.log(error)
            }
        }
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item label='Имя клиента'>
                    <Text>{findUniqueRequest.user.name}</Text>
                </Form.Item>
                <Form.Item label='Номер клиента'>
                    <Text>+{findUniqueRequest.user.phone}</Text>
                </Form.Item>
                <Form.Item label='Услуга'>
                    <Text>{findUniqueRequest.category.name}</Text>
                </Form.Item>
                <Form.Item label='Дата и время брони'>
                    <Text>{findUniqueRequest.date} {findUniqueRequest.times[0]}</Text>
                </Form.Item>
                {
                    findUniqueRequest.times.length > 0 ? (
                        <Form.Item label='Время от и до'>
                            <TimePicker style={{ marginRight: 12 }} defaultValue={dayjs(findUniqueRequest.times[0], format)} minuteStep={15} format={format} />
                            <TimePicker defaultValue={dayjs(findUniqueRequest.times[0], format).add(15, 'minute')} minuteStep={15} format={format} />
                        </Form.Item>
                    ) : null
                }
                {
                    findUniqueRequest.master ? (
                        <Form.Item name='master' label='Мастер'>
                            <Select
                                placeholder='Выберите мастера'
                                options={company?.masters?.map(object => ({
                                    value: object.id,
                                    label: object.name
                                }))}
                                mode='multiple'
                                optionFilterProp='label'
                            />
                        </Form.Item>
                    ) : null
                }
                <Form.Item name='price' label='Договорная цена'>
                    <InputNumber placeholder='Цена' />
                </Form.Item>
                <Form.Item name='status' label='Статус'>
                    <Select
                        placeholder='Выберите категорию'
                        options={[
                            { value: '0', label: 'Новая' },
                            { value: '1', label: 'Принят' },
                            { value: '3', label: 'Завершен' },
                            { value: '4', label: 'Отменен' }
                        ]}
                    />
                </Form.Item>
                <Button loading={updateOneRequestLoading} type='primary' htmlType='submit'>
                    Сохранить
                </Button>
            </Form>
        </>
    )
}

export default Request
