import React from 'react'
import ReactDOM from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import { ConfigProvider } from 'antd'

import locale from 'antd/es/locale/ru_RU'
import './index.css'

import client from './utils/Apollo'
import Router from './Router'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ConfigProvider locale={locale}>
        <Router />
      </ConfigProvider>
    </ApolloProvider>
  </React.StrictMode>
)