import { useQuery } from '@apollo/client'
import { FIND_CURRENT_COMPANY } from '../gqls'

export const useCompany = (params = {}) => {
    const { onCompleted = () => { }, onError = () => { }, fetchPolicy = 'network-only' } = params

    const { data, loading, error, refetch } = useQuery(FIND_CURRENT_COMPANY, {
        onCompleted,
        onError,
        fetchPolicy
    })

    const company = data ? data.findCurrentCompany : null

    return {
        loading,
        error,
        company,
        refetch
    }
}