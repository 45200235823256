import React, { useState } from 'react'
import styled from 'styled-components'
import { Form as AntForm, Button, message, Select, Input, Upload, Popconfirm } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'

import { FIND_UNIQUE_MASTER, UPDATE_ONE_MASTER, DELETE_ONE_MASTER } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const Master = () => {
    const [form] = Form.useForm()
    const { id } = useParams()
    const navigate = useNavigate()
    const [image, setImage] = useState([])

    const { loading, error } = useQuery(FIND_UNIQUE_MASTER, {
        variables: {
            where: {
                id
            }
        },
        onCompleted: ({ findUniqueMaster }) => {
            form.setFieldsValue({
                name: findUniqueMaster.name,
                categories: findUniqueMaster.categories,
                description: findUniqueMaster.description,
            })
            if (findUniqueMaster.image) {
                const object = findUniqueMaster.image
                setImage([
                    {
                        uid: 1,
                        name: object,
                        status: 'done',
                        url: `https://www.okoshkiykt.ru/uploads/${object}`,
                        response: {
                            filename: object
                        }
                    }
                ])
            }
        }
    })

    const [updateOneMasterMutation, { loading: updateOneMasterLoading }] = useMutation(UPDATE_ONE_MASTER, {
        onCompleted: () => {
            message.success('Данные обновлены')
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const [deleteOneMasterMutation] = useMutation(DELETE_ONE_MASTER, {
        variables: {
            where: {
                id
            }
        },
        onCompleted: () => {
            message.success('Товар удален')
            navigate(-1)
        },
        onError: (err) => {
            console.error(err)
            message.error('Не удалось удалить')
        },
    })

    if (loading || error) return null

    const handleSubmit = async (values) => {
        const setImage = image[0]?.response?.filename || null
        await updateOneMasterMutation({
            variables: {
                where: {
                    id
                },
                data: {
                    name: {
                        set: values.name
                    },
                    description: {
                        set: values.description
                    },
                    image: {
                        set: setImage
                    },
                }
            }
        })
    }

    const onDelete = async () => {
        await deleteOneMasterMutation()
    }

    const onPreview = async (file) => {
        const name = file.response.filename
        window.open(`https://www.okoshkiykt.ru/uploads/${name}`, '_blank')
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item label='Изображение (Нажмите чтобы загрузить)' >
                    <Upload
                        maxCount={1}
                        action='https://www.okoshkiykt.ru/upload'
                        listType='picture-card'

                        fileList={image}
                        onChange={({ fileList }) => {
                            setImage(fileList)
                        }}
                        onPreview={onPreview}
                    >
                        {image.length < 1 && '+ Загрузить'}
                    </Upload>
                </Form.Item>
                <Form.Item name='name' label='Имя мастера'>
                    <Input placeholder='Введите имя' />
                </Form.Item>
                <Form.Item name='description' label='Описание мастера'>
                    <Input.TextArea placeholder='Введите описание мастера' />
                </Form.Item>
                <Button loading={updateOneMasterLoading} type='primary' htmlType='submit'>
                    Сохранить
                </Button>
                <Popconfirm title='Удалить?' onConfirm={onDelete}>
                    <Button type='primary' danger style={{ marginLeft: 5 }}>
                        Удалить
                    </Button>
                </Popconfirm>
            </Form>
        </>
    )
}

export default Master
