import React, { useState } from 'react'
import styled from 'styled-components'
import { Form as AntForm, Button, message, Select, Input, Upload } from 'antd'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { useCompany } from '../hooks/company'

import { CREATE_ONE_MASTER } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const AddMaster = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [image, setImage] = useState([])

    const { company } = useCompany()

    const [createOneMasterMutation, { loading: createOneMasterLoading }] = useMutation(CREATE_ONE_MASTER, {
        onCompleted: () => {
            form.resetFields()
            message.success('Мастер добавлен')
            navigate(-1)
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })


    const handleSubmit = async (values) => {
        const createImage = image[0]?.response?.filename || null

        await createOneMasterMutation({
            variables: {
                data: {
                    name: values.name,
                    description: values.description,
                    image: createImage,
                    company: {
                        connect: {
                            id: company.id
                        }
                    }
                }
            }
        })
    }

    const onPreview = async (file) => {
        const name = file.response.filename
        window.open(`https://www.okoshkiykt.ru/uploads/${name}`, '_blank')
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item name='image' label='Изображение (Нажмите чтобы загрузить)' rules={[rules.required]} >
                    <Upload
                        maxCount={1}
                        action='https://www.okoshkiykt.ru/upload'
                        listType='picture-card'

                        fileList={image}
                        onChange={({ fileList }) => {
                            setImage(fileList)
                        }}
                        onPreview={onPreview}
                    >
                        {image.length < 1 && '+ Загрузить'}
                    </Upload>
                </Form.Item>
                <Form.Item name='name' label='Имя мастера' rules={[rules.required]}>
                    <Input placeholder='Введите имя' />
                </Form.Item>
                <Form.Item name='description' label='Описание мастера' rules={[rules.required]}>
                    <Input.TextArea placeholder='Введите описание мастера' />
                </Form.Item>
                <Button loading={createOneMasterLoading} type='primary' htmlType='submit'>
                    Добавить
                </Button>
            </Form>
        </>
    )
}

export default AddMaster
