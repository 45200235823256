import styled from 'styled-components'
import { Layout as AntLayout, Button, Popconfirm, Menu } from 'antd'
import { Outlet, Link } from 'react-router-dom'

const { Content: AntContent, Sider: AntSider } = AntLayout

const Content = styled(AntContent)`
    margin: 20px 20px 0;

    @media only screen and (max-width: 480px) {
        margin: 10px 0;
    }

    .inside {
        padding: 18px 22px;
        min-height: 100%;

        @media only screen and (max-width: 480px) {
            padding: 20px 14px;
        }
    }
`

const Provider = styled(AntLayout)`
    min-height: 100vh;

    .ant-layout-sider-zero-width-trigger {
        top: 11px;
    }
`

const Sider = styled(AntSider)`
    z-index: 3;

    @media only screen and (max-width: 992px) {
        position: absolute;
        height: 100%;
    }
`

const MenuLink = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
        color: white;
    }
`

export default function AdminDrawer() {


    const logOut = async () => {
        await localStorage.clear()
        window.location.replace('/')
    }

    return (
        <Provider>
            <Sider style={{ minHeight: '100vh' }} breakpoint='lg' collapsedWidth='0'>
                <Menu style={{ height: '50%', paddingBottom: 24 }} theme='dark' mode='inline' defaultSelectedKeys={['/companies']}>
                    <Menu.Item key={`/companies`}>
                        <MenuLink to={`/companies`}>
                            Компании
                        </MenuLink>
                    </Menu.Item>
                    <Menu.Item key={`/categories`}>
                        <MenuLink to={`/categories`}>
                            Категории
                        </MenuLink>
                    </Menu.Item>
                    <Menu.Item key={`/promotions`}>
                        <MenuLink to={`/promotions`}>
                            Реклама
                        </MenuLink>
                    </Menu.Item>
                </Menu>
                <div style={{ display: 'flex', height: '50%', justifyContent: 'center', alignItems: 'flex-end' }}>
                    <Popconfirm title='Выйти?' onConfirm={logOut}>
                        <Button style={{ width: 'calc(100% - 12px)', marginBottom: 12 }} danger ghost type='primary'>Выйти с аккаунта</Button>
                    </Popconfirm>
                </div>
            </Sider>
            <AntLayout>
                <Content>
                    <div className='inside'>
                        <Outlet />
                    </div>
                </Content>
            </AntLayout>
        </Provider>
    );
}
