import React, { useState } from 'react'
import styled from 'styled-components'
import Moment from 'moment'
import 'moment/locale/ru'
import { extendMoment } from 'moment-range'
import { Form as AntForm, Button, message, Select, Input, Upload, Popconfirm } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'

import { useCompany } from '../hooks/company'

import { FIND_UNIQUE_SCHEDULE, UPDATE_ONE_SCHEDULE, DELETE_ONE_SCHEDULE, FIND_MANY_MASTER, FIND_MANY_SERVICE } from '../gqls'

const moment = extendMoment(Moment)

const Form = styled(AntForm)`
    max-width: 600px;
`

const week = [
    {
        value: 'Понедельник',
        label: 'Понедельник'
    },
    {
        value: 'Вторник',
        label: 'Вторник'
    },
    {
        value: 'Среда',
        label: 'Среда'
    },
    {
        value: 'Четверг',
        label: 'Четверг'
    },
    {
        value: 'Пятница',
        label: 'Пятница'
    },
    {
        value: 'Суббота',
        label: 'Суббота'
    },
    {
        value: 'Воскресенье',
        label: 'Воскресенье'
    }
]

const Schedule = () => {
    const [form] = Form.useForm()
    const { id } = useParams()
    const { company } = useCompany()
    const navigate = useNavigate()

    const [masters, setMasters] = useState([])
    const [services, setServices] = useState([])

    const { loading, error } = useQuery(FIND_UNIQUE_SCHEDULE, {
        variables: {
            where: {
                id
            }
        },
        onCompleted: ({ findUniqueSchedule }) => {
            form.setFieldsValue({
                week: findUniqueSchedule.week,
                times: findUniqueSchedule.times,
                masters: findUniqueSchedule.masters.map(object => object.id),
                services: findUniqueSchedule.services.map(object => object.id)
            })
        }
    })

    useQuery(FIND_MANY_MASTER, {
        variables: {
            where: {
                companyId: {
                    equals: company?.id
                }
            }
        },
        onCompleted: ({ findManyMaster }) => {
            const mastersData = findManyMaster.map(object => ({
                label: object.name,
                value: object.id,
            }))
            setMasters(mastersData)
        },
        fetchPolicy: 'network-only'
    })

    useQuery(FIND_MANY_SERVICE, {
        variables: {
            where: {
                companyId: {
                    equals: company?.id
                }
            }
        },
        onCompleted: ({ findManyService }) => {
            const servicesData = findManyService.map(object => ({
                label: (object?.name || object?.category?.name) || 'Отсутвует',
                value: object.id,
            }))
            setServices(servicesData)
        },
        fetchPolicy: 'network-only'
    })

    const [updateOneScheduleMutation, { loading: updateOneScheduleLoading }] = useMutation(UPDATE_ONE_SCHEDULE, {
        onCompleted: () => {
            message.success('Данные обновлены')
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const [deleteOneScheduleMutation] = useMutation(DELETE_ONE_SCHEDULE, {
        variables: {
            where: {
                id
            }
        },
        onCompleted: () => {
            message.success('Товар удален')
            navigate(-1)
        },
        onError: (err) => {
            console.error(err)
            message.error('Не удалось удалить')
        },
    })

    if (loading || error) return null

    const handleSubmit = async (values) => {
        await updateOneScheduleMutation({
            variables: {
                where: {
                    id
                },
                data: {
                    times: values.times || [],
                    week: values.week,
                    masters: values.masters || values.masters?.length > 0 ? {
                        connect: values.masters.map(object => ({
                            id: object
                        }))
                    } : undefined,
                    services: {
                        set: values.services.map(object => ({
                            id: object
                        }))
                    },
                }
            }
        })
    }

    const onDelete = async () => {
        await deleteOneScheduleMutation()
    }

    const getTimesRange = () => {
        const dayStart = moment().startOf('day').hours(0).minutes(0)
        const dayEnd = moment().startOf('day').hours(23).minutes(59)
        const range = moment.range(dayStart, dayEnd)
        const timeArray = Array.from(range.by('minutes', { step: 15 }))

        return timeArray.map(object => ({
            value: object.format('HH:mm'),
            label: object.format('HH:mm') + ' - ' + object.add(15, 'minutes').format('HH:mm'),
        }))
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item name='week' label='Выберите день недели'>
                    <Select
                        placeholder='Выберите день недели'
                        options={week}
                        mode='multiple'
                        optionFilterProp='label'
                    />
                </Form.Item>
                <Form.Item name='times' label='Выберите интервал'>
                    <Select
                        mode='multiple'
                        placeholder='Выберите интервал'
                        options={getTimesRange()}
                        optionFilterProp='label'
                    />
                </Form.Item>
                <Form.Item name='masters' label='Выберите мастера'>
                    <Select
                        placeholder='Выберите мастера'
                        options={masters}
                        mode='multiple'
                        optionFilterProp='label'
                    />
                </Form.Item>
                <Form.Item name='services' label='Выберите услугу'>
                    <Select
                        placeholder='Выберите услугу'
                        options={services}
                        mode='multiple'
                        optionFilterProp='label'
                    />
                </Form.Item>

                <Button loading={updateOneScheduleLoading} type='primary' htmlType='submit'>
                    Сохранить
                </Button>
                <Popconfirm title='Удалить?' onConfirm={onDelete}>
                    <Button type='primary' danger style={{ marginLeft: 5 }}>
                        Удалить
                    </Button>
                </Popconfirm>
            </Form>
        </>
    )
}

export default Schedule
