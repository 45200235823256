import React, { useState } from 'react'
import styled from 'styled-components'
import { Form as AntForm, Button, message, Input, Upload } from 'antd'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { CREATE_ONE_CATEGORY } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const AddCategory = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [image, setImage] = useState([])

    const [createOneCategoryMutation, { loading: createOneCategoryLoading }] = useMutation(CREATE_ONE_CATEGORY, {
        onCompleted: () => {
            form.resetFields()
            message.success('Категория добавлена')
            navigate(-1)
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const handleSubmit = async (values) => {
        const createImage = image[0]?.response?.filename || null

        await createOneCategoryMutation({
            variables: {
                data: {
                    name: values.name,
                    image: createImage,
                }
            }
        })
    }

    const onPreview = async (file) => {
        const name = file.response.filename
        window.open(`https://www.okoshkiykt.ru/uploads/${name}`, '_blank')
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item rules={[rules.required]} name='image' label='Изображение (Нажмите чтобы загрузить)'>
                    <Upload
                        maxCount={1}
                        action='https://www.okoshkiykt.ru/upload'
                        listType='picture-card'
                        fileList={image}
                        onChange={({ fileList }) => {
                            setImage(fileList)
                        }}
                        onPreview={onPreview}
                    >
                        {image.length < 1 && '+ Загрузить'}
                    </Upload>
                </Form.Item>
                <Form.Item name='name' label='Название категории' rules={[rules.required]}>
                    <Input placeholder='Введите название' />
                </Form.Item>
                <Button loading={createOneCategoryLoading} type='primary' htmlType='submit'>
                    Добавить
                </Button>
            </Form>
        </>
    )
}

export default AddCategory
