import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Form as AntForm, Button, message, Input, Upload, Popconfirm, Switch } from 'antd'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'

import { FIND_UNIQUE_COMPANY, UPDATE_ONE_COMPANY, DELETE_ONE_COMPANY } from '../gqls'

import { useCompany } from '../hooks/company'

const Form = styled(AntForm)`
    max-width: 600px;
`

const Company = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const params = useParams()

    const [avatar, setAvatar] = useState([])
    const [images, setImages] = useState([])

    const { company } = useCompany()

    const id = params?.id || company?.id

    const [findUniqueCompanyQuery, { data, loading, error }] = useLazyQuery(FIND_UNIQUE_COMPANY, {
        onCompleted: ({ findUniqueCompany }) => {
            form.setFieldsValue({
                name: findUniqueCompany.name,
                description: findUniqueCompany.description,
                location: findUniqueCompany.location,
                address: findUniqueCompany.address,
                phone: findUniqueCompany.phone,
                whatsapp: findUniqueCompany.whatsapp,
            })
            const companyImages = findUniqueCompany?.images?.map((object, index) => ({
                uid: index,
                name: object,
                status: 'done',
                url: `https://www.okoshkiykt.ru/uploads/${object}`,
                response: {
                    filename: object
                }
            }))
            setImages(companyImages)
            if (findUniqueCompany.avatar) {
                const object = findUniqueCompany.avatar
                setAvatar([
                    {
                        uid: 1,
                        name: object,
                        status: 'done',
                        url: `https://www.okoshkiykt.ru/uploads/${object}`,
                        response: {
                            filename: object
                        }
                    }
                ])
            }
        }
    })

    useEffect(() => {
        if (id) {
            findUniqueCompanyQuery({
                variables: {
                    where: {
                        id
                    }
                }
            })
        }
    }, [id])

    const [updateOneCompanyMutation, { loading: updateOneCompanyLoading }] = useMutation(UPDATE_ONE_COMPANY, {
        onCompleted: () => {
            message.success('Данные обновлены')
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const [deleteOneCompanyMutation] = useMutation(DELETE_ONE_COMPANY, {
        onCompleted: () => {
            message.success('Компания удалена')
            navigate(-1)
        },
        onError: (err) => {
            console.error(err)
            message.error('Не удалось удалить')
        },
    })

    if (loading || error) return null

    const handleSubmit = async (values) => {
        const setImages = images.map(object => object.response.filename) || []
        const setAvatar = avatar[0]?.response?.filename || null

        await updateOneCompanyMutation({
            variables: {
                where: {
                    id
                },
                data: {
                    name: {
                        set: values.name
                    },
                    phone: {
                        set: values.phone
                    },
                    whatsapp: {
                        set: values.whatsapp
                    },
                    description: {
                        set: values.description
                    },
                    avatar: {
                        set: setAvatar
                    },
                    address: {
                        set: values.address
                    },
                    location: {
                        set: values.location
                    },
                    images: setImages,
                }
            }
        })
    }

    const onDelete = async () => {
        if (id) {
            await deleteOneCompanyMutation({
                variables: {
                    where: {
                        id
                    }
                }
            })
        }
    }

    const onPreview = async (file) => {
        const name = file.response.filename
        window.open(`https://www.okoshkiykt.ru/uploads/${name}`, '_blank')
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item label='Аватар (Нажмите чтобы загрузить)' >
                    <Upload
                        maxCount={1}
                        action='https://www.okoshkiykt.ru/upload'
                        listType='picture-card'

                        fileList={avatar}
                        onChange={({ fileList }) => {
                            setAvatar(fileList)
                        }}
                        onPreview={onPreview}
                    >
                        {avatar.length < 1 && '+ Загрузить'}
                    </Upload>
                </Form.Item>
                <Form.Item label='Изображение' >
                    <Upload
                        maxCount={5}
                        action='https://www.okoshkiykt.ru/upload'
                        listType='picture-card'
                        fileList={images}
                        onChange={({ fileList }) => {
                            setImages(fileList)
                        }}
                        onPreview={onPreview}
                    >
                        {images.length < 5 && '+ Загрузить'}
                    </Upload>
                </Form.Item>
                <Form.Item name='name' label='Название компании'>
                    <Input placeholder='Введите имя' />
                </Form.Item>
                <Form.Item name='phone' label='Номер телефона'>
                    <Input placeholder='Введите номер телефона' />
                </Form.Item>
                <Form.Item name='whatsapp' label='WhatsApp'>
                    <Input placeholder='Введите WhatsApp' />
                </Form.Item>
                <Form.Item name='description' label='Описание'>
                    <Input.TextArea placeholder='Введите описание' />
                </Form.Item>
                <Form.Item name='location' label='Локация'>
                    <Input placeholder='Введите локацию' />
                </Form.Item>
                <Form.Item name='address' label='Адрес'>
                    <Input placeholder='Введите адрес' />
                </Form.Item>
                <Button loading={updateOneCompanyLoading} type='primary' htmlType='submit'>
                    Сохранить
                </Button>
                {
                    !company ? (
                        <Popconfirm title='Удалить?' onConfirm={onDelete}>
                            <Button type='primary' danger style={{ marginLeft: 5 }}>
                                Удалить
                            </Button>
                        </Popconfirm>
                    ) : null
                }
            </Form>
        </>
    )
}

export default Company
