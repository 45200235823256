import React, { useState } from 'react'
import styled from 'styled-components'
import { Form as AntForm, Button, message, Input, Upload } from 'antd'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { CREATE_ONE_PROMOTION } from '../gqls'

const { TextArea } = Input

const Form = styled(AntForm)`
    max-width: 600px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const AddPromotion = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [image, setImage] = useState([])

    const [createOnePromotionMutation, { loading: createOnePromotionLoading }] = useMutation(CREATE_ONE_PROMOTION, {
        onCompleted: () => {
            form.resetFields()
            message.success('Реклама добавлена')
            navigate(-1)
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const handleSubmit = async (values) => {
        const createImage = image[0]?.response?.filename || null

        await createOnePromotionMutation({
            variables: {
                data: {
                    name: values.name,
                    text: values.text,
                    image: createImage,
                }
            }
        })
    }

    const onPreview = async (file) => {
        const name = file.response.filename
        window.open(`https://www.okoshkiykt.ru/uploads/${name}`, '_blank')
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item name='image' label='Изображение (Нажмите чтобы загрузить)' rules={[rules.required]} >
                    <Upload
                        maxCount={1}
                        action='https://www.okoshkiykt.ru/upload'
                        listType='picture-card'

                        fileList={image}
                        onChange={({ fileList }) => {
                            setImage(fileList)
                        }}
                        onPreview={onPreview}
                    >
                        {image.length < 1 && '+ Загрузить'}
                    </Upload>
                </Form.Item>
                <Form.Item name='name' label='Название' rules={[rules.required]}>
                    <Input placeholder='Введите название' />
                </Form.Item>
                <Form.Item name='text' label='Текст' rules={[rules.required]}>
                    <TextArea placeholder='Текст' />
                </Form.Item>
                <Button loading={createOnePromotionLoading} type='primary' htmlType='submit'>
                    Добавить
                </Button>
            </Form>
        </>
    )
}

export default AddPromotion
