import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { Form as AntForm, Button, message, Input, Upload, Switch } from 'antd'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { CREATE_ONE_COMPANY } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const AddCompany = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const map = useRef(null)

    const [avatar, setAvatar] = useState([])
    const [images, setImages] = useState([])

    const [createOneCompanyMutation, { loading: createOneCompanyLoading }] = useMutation(CREATE_ONE_COMPANY, {
        onCompleted: () => {
            form.resetFields()
            message.success('Компания добавлена')
            navigate(-1)
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const handleSubmit = async (values) => {
        const setImages = images.map(object => object.response.filename) || []
        const setAvatar = avatar[0]?.response?.filename || null
        await createOneCompanyMutation({
            variables: {
                data: {
                    name: values.name,
                    description: values.description,
                    email: values.email,
                    password: values.password,
                    images: setImages,
                    avatar: setAvatar,
                    address: values.address,
                    location: values.location,
                }
            }
        })
    }

    const onPreview = async (file) => {
        const name = file.response.filename
        window.open(`https://www.okoshkiykt.ru/uploads/${name}`, '_blank')
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item label='Аватар (Нажмите чтобы загрузить)' >
                    <Upload
                        maxCount={1}
                        action='https://www.okoshkiykt.ru/upload'
                        listType='picture-card'

                        fileList={avatar}
                        onChange={({ fileList }) => {
                            setAvatar(fileList)
                        }}
                        onPreview={onPreview}
                    >
                        {avatar.length < 1 && '+ Загрузить'}
                    </Upload>
                </Form.Item>
                <Form.Item label='Изображение' >
                    <Upload
                        maxCount={5}
                        action='https://www.okoshkiykt.ru/upload'
                        listType='picture-card'
                        fileList={images}
                        onChange={({ fileList }) => {
                            setImages(fileList)
                        }}
                        onPreview={onPreview}
                    >
                        {images.length < 5 && '+ Загрузить'}
                    </Upload>
                </Form.Item>
                <Form.Item rules={[rules.required]} name='name' label='Название компании'>
                    <Input placeholder='Введите имя' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='email' label='Email компании'>
                    <Input placeholder='Введите email' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='password' label='Пароль'>
                    <Input placeholder='Введите пароль' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='description' label='Описание'>
                    <Input.TextArea placeholder='Введите описание' />
                </Form.Item>
                <Form.Item name='location' label='Локация'>
                    <Input placeholder='Введите локацию' />
                </Form.Item>
                <Form.Item name='address' label='Адрес'>
                    <Input placeholder='Введите адрес' />
                </Form.Item>
                <Button loading={createOneCompanyLoading} type='primary' htmlType='submit'>
                    Добавить
                </Button>
            </Form>
        </>
    )
}

export default AddCompany
