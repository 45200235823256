import { useState, useMemo } from 'react'
import {
    Table,
    Button,
    Form,
    Input,
    Select
} from 'antd'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import styled from 'styled-components'

import Title from '../components/Title'

import { FIND_MANY_SCHEDULE, FIND_MANY_SCHEDULE_COUNT } from '../gqls'

import { useCompany } from '../hooks/company'

const Filters = styled(Form)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .item {
        width: 200px;
        margin-right: 15px;
    }
`

const weeks = [
    {
        value: 'Понедельник',
        label: 'Понедельник'
    },
    {
        value: 'Вторник',
        label: 'Вторник'
    },
    {
        value: 'Среда',
        label: 'Среда'
    },
    {
        value: 'Четверг',
        label: 'Четверг'
    },
    {
        value: 'Пятница',
        label: 'Пятница'
    },
    {
        value: 'Суббота',
        label: 'Суббота'
    },
    {
        value: 'Воскресенье',
        label: 'Воскресенье'
    }
]

const limit = 10

const Schedules = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [search, setSearch] = useState('')
    const [week, setWeek] = useState(null)

    const { company } = useCompany()

    const variables = useMemo(() => ({
        where: {
            companyId: {
                equals: company?.id
            },
            OR: [
                {
                    company: {
                        is: {
                            name: { contains: search, mode: 'insensitive' }
                        }
                    },
                },
                {
                    services: {
                        every: {
                            category: {
                                is: {
                                    name: { contains: search, mode: 'insensitive' }
                                }
                            },
                        }
                    },
                },
                {
                    masters: {
                        every: {
                            name: { contains: search, mode: 'insensitive' }
                        }
                    },
                },
            ],
            week: week ? { has: week } : undefined
        }
    }), [search, company, week])

    const { data: findManyScheduleData, loading: findManyScheduleLoading } = useQuery(FIND_MANY_SCHEDULE, {
        variables: {
            ...variables,
            take: limit * currentPage,
            skip: 0,
            orderBy: {
                createdAt: 'desc'
            },
        },
        fetchPolicy: 'network-only'
    })

    const { data: findManyScheduleCountData, loading: findManyScheduleCountLoading } = useQuery(FIND_MANY_SCHEDULE_COUNT, {
        variables,
        fetchPolicy: 'network-only'
    })

    const findManySchedule = findManyScheduleData?.findManySchedule || []
    const findManyScheduleCount = findManyScheduleCountData?.findManyScheduleCount || 0

    const handleChangeTable = ({ current }) => setCurrentPage(current)

    return (
        <>
            <Title
                text={`Список графиков (${findManyScheduleCount})`}
                action={
                    <Link to={'add'}>
                        <Button type='primary'>Добавить</Button>
                    </Link>
                }
            />
            <Filters>
                <Input
                    placeholder='Поиск'
                    allowClear
                    onChange={e => setSearch(e.target.value ? e.target.value : '')}
                    className='item'
                />
                <Select
                    className='item'
                    allowClear
                    placeholder='День недели'
                    onSelect={data => setWeek(data)}
                    onClear={() => setWeek(null)}
                >
                    {
                        weeks.map((object, index) => (
                            <Select.Option key={object.value}>
                                {object.label}
                            </Select.Option>
                        ))
                    }
                </Select>
            </Filters>
            <Table
                loading={findManyScheduleLoading || findManyScheduleCountLoading}
                rowKey={(obj) => obj.id}
                dataSource={findManySchedule}
                scroll={{ x: 600 }}
                size={window.innerWidth < 500 ? 'small' : 'large'}
                pagination={{
                    current: currentPage,
                    total: findManyScheduleCount,
                    pageSize: 10
                }}
                onChange={handleChangeTable}
                columns={[
                    {
                        title: 'id',
                        dataIndex: 'id',
                        key: 'id',
                        render: (id) => <Link to={id}>{id}</Link>
                    },
                    {
                        title: 'Дни недели',
                        dataIndex: 'week',
                        key: 'week',
                        render: (week) => <span>{week.join(', ')}</span>
                    },
                    {
                        title: 'Временной интервал',
                        dataIndex: 'times',
                        key: 'times',
                        render: (times) => <span>{times.map(object => object + ' - ' + dayjs(object, 'HH:mm').add(15, 'minutes').format('HH:mm')).join(', ') || '-'}</span>
                    },
                    {
                        title: 'Мастера',
                        dataIndex: 'masters',
                        key: 'masters',
                        render: (masters) => <span>{masters.map(object => object.name).join(', ') || '-'}</span>
                    },
                    {
                        title: 'Услуги',
                        dataIndex: 'services',
                        key: 'services',
                        render: (services) => <span>{services.map(object => object?.category?.name || 'Удалена').join(', ')}</span>
                    },
                ]}

            />
        </>
    )
}

export default Schedules