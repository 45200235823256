import { useState, useMemo } from 'react'
import {
    Table,
    Button,
    Popconfirm,
    Input,
    Form,
    message,
    Select
} from 'antd'
import { useQuery, useApolloClient } from '@apollo/client'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Title from '../components/Title'

import { FIND_MANY_REQUEST, FIND_MANY_REQUEST_COUNT, UPDATE_ONE_REQUEST } from '../gqls'

import { useCompany } from '../hooks/company'

const Filters = styled(Form)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .item {
        width: 200px;
        margin-right: 15px;
    }
`

const limit = 10

const statuses = {
    '0': 'Новый',
    '1': 'Принят',
    '3': 'Завершен',
    '4': 'Отменен'
}

const Couriers = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [code, setCode] = useState('')
    const [search, setSearch] = useState('')
    const [status, setStatus] = useState(null)

    const { company } = useCompany()

    const client = useApolloClient()

    const variables = useMemo(() => ({
        where: {
            companyId: {
                equals: company?.id
            },
            OR: [
                {
                    company: {
                        is: {
                            name: { contains: search, mode: 'insensitive' }
                        }
                    },
                },
                {
                    master: {
                        is: {
                            name: { contains: search, mode: 'insensitive' }
                        }
                    },
                },
                {
                    category: {
                        is: {
                            name: { contains: search, mode: 'insensitive' }
                        }
                    },
                },
                {
                    user: {
                        is: {
                            phone: { contains: search, mode: 'insensitive' }
                        }
                    },
                },
                {
                    user: {
                        is: {
                            name: { contains: search, mode: 'insensitive' }
                        }
                    },
                },
            ],
            status: status ? { equals: status } : undefined
        }
    }), [search, company, status])

    const { data: findManyRequestData, loading: findManyRequestLoading } = useQuery(FIND_MANY_REQUEST, {
        variables: {
            ...variables,
            take: limit * currentPage,
            skip: 0,
            orderBy: {
                createdAt: 'desc'
            }
        },
        fetchPolicy: 'network-only'
    })

    const { data: findManyRequestCountData, loading: findManyRequestCountLoading } = useQuery(FIND_MANY_REQUEST_COUNT, {
        variables,
        fetchPolicy: 'network-only'
    })

    const findManyRequest = findManyRequestData?.findManyRequest || []
    const findManyRequestCount = findManyRequestCountData?.findManyRequestCount || 0

    const handleChangeTable = ({ current }) => setCurrentPage(current)

    const onConfirm = async () => {
        const { data } = await client.query({
            query: FIND_MANY_REQUEST,
            variables: {
                where: {
                    code: {
                        equals: code
                    },
                    companyId: {
                        equals: company.id
                    }
                }
            },
            fetchPolicy: 'network-only'
        })
        if (data && data.findManyRequest && data.findManyRequest.length > 0) {
            const [request] = data.findManyRequest
            const update = await client.mutate({
                mutation: UPDATE_ONE_REQUEST,
                variables: {
                    where: {
                        id: request.id
                    },
                    data: {
                        status: {
                            set: '3'
                        }
                    }
                }
            })
            if (update && update.data && update.data.updateOneRequest) {
                message.success('Запись подтверждена')
            }
        } else {
            message.error('Запись не найдена')
        }
        setCode('')
    }

    return (
        <>
            <Title
                text={`Список заявок (${findManyRequestCount})`}
                action={
                    <Popconfirm
                        title='Введите код'
                        description={() => (
                            <Input value={code} onChange={(event) => setCode(event.target.value)} placeholder='Код заявки' />
                        )}
                        onConfirm={onConfirm}
                        onCancel={() => setCode('')}
                    >
                        <Button type='primary'>Подтвердить</Button>
                    </Popconfirm>
                }
            />
            <Filters>
                <Input
                    placeholder='Поиск'
                    allowClear
                    onChange={e => setSearch(e.target.value ? e.target.value : '')}
                    className='item'
                />
                <Select
                    className='item'
                    allowClear
                    placeholder='Статус'
                    onSelect={data => setStatus(data)}
                    onClear={() => setStatus(null)}
                >
                    {
                        Object.keys(statuses).map(key => (
                            <Select.Option
                                key={key}
                            >
                                {statuses[key]}
                            </Select.Option>
                        ))
                    }
                </Select>
            </Filters>
            <Table
                loading={findManyRequestLoading || findManyRequestCountLoading}
                rowKey={(obj) => obj.id}
                dataSource={findManyRequest}
                scroll={{ x: 600 }}
                size={window.innerWidth < 500 ? 'small' : 'large'}
                pagination={{
                    current: currentPage,
                    total: findManyRequestCount,
                    pageSize: 10
                }}
                onChange={handleChangeTable}
                columns={[
                    {
                        title: 'id',
                        dataIndex: 'id',
                        key: 'id',
                        render: (id) => <Link to={id}>{id}</Link>
                    },
                    {
                        title: 'Услуга',
                        dataIndex: 'category',
                        key: 'category',
                        render: (category) => <span>{category?.name}</span>
                    },
                    {
                        title: 'Дата',
                        dataIndex: 'date',
                        key: 'date',
                        render: (date) => <span>{date}</span>
                    },
                    {
                        title: 'Время',
                        dataIndex: 'times',
                        key: 'times',
                        render: (times) => <span>{times[0]}</span>
                    },
                    {
                        title: 'Имя клиента',
                        dataIndex: 'user',
                        key: 'user',
                        render: (user) => <span>{user.name}</span>
                    },
                    {
                        title: 'Номер телефона клиента',
                        dataIndex: 'user',
                        key: 'user',
                        render: (user) => <span>+{user.phone}</span>
                    },
                    {
                        title: 'Мастер',
                        dataIndex: 'master',
                        key: 'master',
                        render: (master) => <span>{master?.name || '-'}</span>
                    },
                    {
                        title: 'Статус',
                        dataIndex: 'status',
                        key: 'status',
                        render: (status) => <span>{statuses[status]}</span>
                    },
                ]}

            />
        </>
    )
}

export default Couriers