import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'

import CompanyDrawer from './components/CompanyDrawer'
import AdminDrawer from './components/AdminDrawer'

import AuthCompany from './pages/AuthCompany'
import AuthAdmin from './pages/AuthAdmin'

import Companies from './pages/Companies'
import Company from './pages/Company'
import AddCompany from './pages/AddCompany'

import Requests from './pages/Requests'
import Request from './pages/Request'

import Masters from './pages/Masters'
import Master from './pages/Master'
import AddMaster from './pages/AddMaster'

import Services from './pages/Services'
import Service from './pages/Service'
import AddService from './pages/AddService'

import Categories from './pages/Categories'
import Category from './pages/Category'
import AddCategory from './pages/AddCategory'

import Promotions from './pages/Promotions'
import AddPromotion from './pages/AddPromotion'
import Promotion from './pages/Promotion'

import Schedules from './pages/Schedules'
import AddSchedule from './pages/AddSchedule'
import Schedule from './pages/Schedule'

import { useCompany } from './hooks/company'
import { useAdmin } from './hooks/admin'

const companyRouter = createBrowserRouter([
    {
        path: '/',
        element: <CompanyDrawer />,
        children: [
            {
                path: '/admin',
                element: <Navigate to='/' />
            },
            {
                path: '/',
                element: <Navigate to='/company' />
            },
            {
                path: 'company',
                element: <Company />
            },
            {
                path: 'requests',
                element: <Requests />
            },
            {
                path: 'requests/:id',
                element: <Request />
            },
            {
                path: 'masters',
                element: <Masters />
            },
            {
                path: 'masters/:id',
                element: <Master />
            },
            {
                path: 'masters/add',
                element: <AddMaster />
            },
            {
                path: 'services',
                element: <Services />
            },
            {
                path: 'services/:id',
                element: <Service />
            },
            {
                path: 'services/add',
                element: <AddService />
            },
            {
                path: 'schedules',
                element: <Schedules />
            },
            {
                path: 'schedules/add',
                element: <AddSchedule />
            },
            {
                path: 'schedules/:id',
                element: <Schedule />
            },
        ],
    },
])

const adminRouter = createBrowserRouter([
    {
        path: '/',
        element: <AdminDrawer />,
        children: [
            {
                path: '/admin',
                element: <Navigate to='/' />
            },
            {
                path: '/',
                element: <Navigate to='/companies' />
            },
            {
                path: 'companies',
                element: <Companies />
            },
            {
                path: 'companies/:id',
                element: <Company />
            },
            {
                path: 'companies/add',
                element: <AddCompany />
            },
            {
                path: 'categories',
                element: <Categories />
            },
            {
                path: 'categories/:id',
                element: <Category />
            },
            {
                path: 'categories/add',
                element: <AddCategory />
            },
            {
                path: 'promotions',
                element: <Promotions />
            },
            {
                path: 'promotions/add',
                element: <AddPromotion />
            },
            {
                path: 'promotions/:id',
                element: <Promotion />
            },
        ],
    },
])

const auth = createBrowserRouter([
    {
        path: '/',
        element: <AuthCompany />,
    },
    {
        path: '/admin',
        element: <AuthAdmin />,
    },
])

const Router = () => {
    const { company, loading: companyLoading } = useCompany()
    const { admin, loading: adminLoading } = useAdmin()

    if (companyLoading || adminLoading) return null

    if (company) {
        return <RouterProvider router={companyRouter} />
    }

    if (admin) {
        return <RouterProvider router={adminRouter} />
    }

    return <RouterProvider router={auth} />
}

export default Router
